import HomePlaceholder from "@components/frontpage/HomePlaceholder";
import mainController from "@controller/mainController";
import { inject, observer } from "mobx-react";
import { i18n, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { LogoJsonLd, NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import { createElement, useEffect } from "react";
import { MainService } from "../src/queries/main/main.service";
import BaseLayout from "../src/ui/layouts/base-layout";

const controller = new mainController();
const IndexPage = inject("CoreStore")(
  observer((props) => {
    const { t } = useTranslation("common");

    useEffect(() => {
      if (props.rawData) controller.getAll(() => {});
      else controller.getAll(() => {});
    }, []);

    const showData = () => {
      const list = [];
      {
        controller.data.map((data, index) => {
          if (controller.componentList.hasOwnProperty(data.component_name)) {
            const path = controller.componentList[data.component_name];
            data.isHome = true;
            try {
              list.push(
                createElement(
                  dynamic(() => path, {
                    ssr: true,
                    error: (e) => {},
                  }),
                  {
                    key: "data" + index,
                    data: data,
                  }
                )
              );
            } catch (error) {
              list.push(<div key={"data" + index}>error load data</div>);
            }
          }
        });
      }
      return list;
    };

    return (
      <div>
        <NextSeo
          title={t("tag.main_meta_title")}
          description={t("tag.main_meta_description")}
          openGraph={{
            url: `${process.env.NEXT_PUBLIC_SITE_ADDRESS}`,
            title: `${t("tag.main_meta_title")}`,
            description: `${t("tag.main_meta_description")}`,
            site_name: "moverii.de",
          }}
          twitter={{
            handle: "@handle",
            site: "@site",
            cardType: "summary_large_image",
          }}
        />
        <LogoJsonLd
          logo="/static/images/logo.svg"
          url={process.env.NEXT_PUBLIC_SITE_ADDRESS}
        />

        <main className={"bfront"}>
          {controller.data?.length > 0 ? showData() : <HomePlaceholder />}
        </main>
      </div>
    );
  })
);
IndexPage.Layout = BaseLayout;

export const getServerSideProps = async ({ locale }) => {
  if (process.env.NODE_ENV === "development") {
    await i18n?.reloadResources();
  }

  let rawData = "";
  await MainService.frontpage()
    .then((data) => {
      rawData = data;
    })
    .catch((e) => {
      console.log(e);
    });

  return {
    props: {
      rawData: JSON.stringify(rawData),
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default IndexPage;
