import Newsletter from "@components/Newsletter/Newsletter";
import { useTranslation } from "next-i18next";

export default function Traveler(props) {
  const { t } = useTranslation("common");
  return (
    <div>
      <div className="w-full bg-[#e1f9fc] pt-[50px] lg:pt-[70px] pb-12">
        <Newsletter
          image1={"/static/images/news.png"}
          image2={
            process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
            "cdn/statics/Newsletter-Element/Aktivurlaub_moverii.jpg"
          }
          image3={
            process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
            "cdn/statics/Newsletter-Element/Yoga-Retreats_moverii.jpg"
          }
          title={t("contact.newsletter_title")}
          description={t("contact.newsletter_description")}
          inputPlaceholder={t("contact.newsletter_placeholder")}
          buttonTitle={t("contact.newsletter_button")}
        />
      </div>
    </div>
  );
}
